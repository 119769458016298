<template>
  <div>
    <Toast />
    <div class="grid grid-cols-1 gap-12">
      <div class="intro-y box p-5 mt-5">
        <h4>Nº de Orden {{ numOrden }}</h4>
      </div>
    </div>
    <!---panalpina u otros-->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8" v-if="service.info_entregas && (permisos.Admin || permisos.Panalpina || permisos.Bdp || permisos.Otros)">
      <h2 class="text-lg font-medium mr-auto">Servicios adicionales</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="btn btn-primary shadow-md mr-2" @click="openNewAdicional">Nuevo</button>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-12">
      <div class="intro-y box p-5 mt-5" v-if="service.info_entregas && (permisos.Admin || permisos.Panalpina || permisos.Bdp || permisos.Otros)">
        <DataTable :value="service.info_entregas[0].servicioAdicional" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Información
              <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
            </div>
          </template>

          <template #empty>
            Datos no disponibles.
          </template>
          <template #loading>
            Cargando datos. Por favor espera.
          </template>

          <Column field="itemServicioAdicional" header="Item" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Item</span>
              {{slotProps.data.itemServicioAdicional}}
            </template>
          </Column>
          <Column field="servicioAdicional" header="Servicio adicional" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Servicio adicional</span>
              {{slotProps.data.servicioAdicional.nombre}}
            </template>
          </Column>
          <Column field="cantidad" header="Cantidad" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Cantidad</span>
              {{slotProps.data.cantidad}}
            </template>
          </Column>
          <Column field="valorTotal" header="Valor total" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Valor total</span>
              {{slotProps.data.valorTotal}}
            </template>
          </Column>

        </DataTable>
      </div>
      <div class="intro-y box p-5 mt-5" v-if="false">
        <h4>Cargue Despacho</h4>

        <Toolbar class="p-mb-4">
          <template v-slot:right>
            <Button label="New" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNewDespacho" />
          </template>
        </Toolbar>
        <DataTable :value="service.info_despacho" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Información
              <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
            </div>
          </template>

          <template #empty>
            Datos no disponibles.
          </template>
          <template #loading>
            Cargando datos. Por favor espera.
          </template>

          <Column field="vehiculo" header="Vehículo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Vehículo</span>
              {{slotProps.data.vehiculo}}
            </template>
          </Column>
          <Column field="vehiculoModelo" header="Modelo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Modelo</span>
              {{slotProps.data.vehiculoModelo}}
            </template>
          </Column>
          <Column field="conductorNombre" header="Nombre conductor" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nombre conductor</span>
              {{slotProps.data.conductorNombre}}
            </template>
          </Column>
          <Column field="manifiesto" header="Manifiesto" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Manifiesto</span>
              {{slotProps.data.manifiesto}}
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8" v-if="service.info_factura && (permisos.Admin || permisos.Panalpina || permisos.Bdp || permisos.Otros)">
      <h2 class="text-lg font-medium mr-auto">Factura</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="btn btn-primary shadow-md mr-2" @click="openNewFactura">Nuevo</button>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-12">
      <div class="intro-y box p-5 mt-5" v-if="service.info_factura && (permisos.Admin || permisos.Panalpina || permisos.Bdp || permisos.Otros)">
        <DataTable :value="service.info_factura" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Información
              <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
            </div>
          </template>

          <template #empty>
            Datos no disponibles.
          </template>
          <template #loading>
            Cargando datos. Por favor espera.
          </template>

          <Column field="numerofactura" header="Nª Factura" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nª Factura</span>
              {{slotProps.data.numerofactura}}
            </template>
          </Column>
          <Column field="fechafactura" header="Fecha" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Fecha</span>
              {{slotProps.data.fechafactura}}
            </template>
          </Column>
          <Column field="valorfactura" header="Valor" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Valor</span>
              {{slotProps.data.valorfactura}}
            </template>
          </Column>
          <Column field="monedafactura" header="Moneda" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Moneda</span>
              {{slotProps.data.monedafactura}}
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
    <!---Operaciones-->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8" v-if="service.info_orden_servicio && (permisos.Admin || permisos.Operaciones)">
      <h2 class="text-lg font-medium mr-auto">Asignar vehículo</h2>
<!--      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">-->
<!--        <button class="btn btn-primary shadow-md mr-2" @click="openNewVehiculo">Nuevo</button>-->
<!--      </div>-->
    </div>
    <div class="grid grid-cols-1 gap-12">
      <div class="intro-y box p-5 mt-5" v-if="service.info_orden_servicio && (permisos.Admin || permisos.Operaciones)">
        <DataTable :value="itemVehiculo" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Información
              <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
            </div>
          </template>

          <template #empty>
            Datos no disponibles.
          </template>
          <template #loading>
            Cargando datos. Por favor espera.
          </template>

          <Column field="placa" header="Placa" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Placa</span>
              {{slotProps.data.Placa}}
            </template>
          </Column>
          <Column field="nombreCond" header="Conductor" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Conductor</span>
              {{slotProps.data.NomConductor}}
            </template>
          </Column>
          <Column field="empSatelital" header="Empresa satelital" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Empresa satelital</span>
              {{slotProps.data.GPSoperador}}
            </template>
          </Column>
          <Column field="observacion" header="Clase vehículo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Clase vehículo</span>
              {{slotProps.data.ClaseVeh}}
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8" v-if="service.info_auxiliares && (permisos.Admin || permisos.Operaciones)">
      <h2 class="text-lg font-medium mr-auto">Auxiliar</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="btn btn-primary shadow-md mr-2" @click="openNewAuxiliar">Nuevo</button>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-12">
      <div class="intro-y box p-5 mt-5" v-if="service.info_auxiliares && (permisos.Admin || permisos.Operaciones)">
        <DataTable :value="service.info_auxiliares" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Información
              <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
            </div>
          </template>

          <template #empty>
            Datos no disponibles.
          </template>
          <template #loading>
            Cargando datos. Por favor espera.
          </template>

          <Column field="auxiliarNombre" header="Nombre" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{slotProps.data.auxiliarNombre}}
            </template>
          </Column>
          <Column field="auxiliarTelefono" header="Teléfono" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Teléfono</span>
              {{slotProps.data.auxiliarTelefono}}
            </template>
          </Column>
          <Column field="auxiliarDocumento" header="Documento" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Documento</span>
              {{slotProps.data.auxiliarDocumento}}
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-12">
      <div class="intro-y box p-5 mt-5" v-if="permisos.Admin || permisos.Operaciones">
        <Fieldset legend="Proceso de entrega" :toggleable="true">
          <Accordion>
            <AccordionTab header="Entrega">
              <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
                <h2 class="text-lg font-medium mr-auto">Entrega de Mercancia</h2>
                <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
                  <button class="btn btn-primary shadow-md mr-2" @click="openNewEntrega_Mercancia">Nuevo</button>
                </div>
              </div>
              <div class="intro-y box p-5 mt-5" v-if="service.info_entrega_mercancia">
                <DataTable :value="service.info_entrega_mercancia" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                           :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
                  <template #header>
                    <div class="table-header">
                      Información
                      <span class="p-input-icon-left">
                        <i class="pi pi-search"/>
                        <InputText v-model="filters['global'].value" placeholder="Search"/>
                      </span>
                    </div>
                  </template>

                  <template #empty>
                    Datos no disponibles.
                  </template>
                  <template #loading>
                    Cargando datos. Por favor espera.
                  </template>

                  <Column field="itemEntrega" header="Item Entrega" :sortable="true">
                    <template #body="slotProps">
                      <span class="p-column-title">Item Entrega</span>
                      {{slotProps.data.itemEntrega}}
                    </template>
                  </Column>
                  <Column field="recibidoNombre" header="Nombre" :sortable="true">
                    <template #body="slotProps">
                      <span class="p-column-title">Nombre</span>
                      {{slotProps.data.recibidoNombre}}
                    </template>
                  </Column>
                  <Column field="recibidoCelular" header="Celular" :sortable="true">
                    <template #body="slotProps">
                      <span class="p-column-title">Celular</span>
                      {{slotProps.data.recibidoCelular}}
                    </template>
                  </Column>
                  <Column field="recibidoFecha" header="Fecha" :sortable="true">
                    <template #body="slotProps">
                      <span class="p-column-title">Fecha</span>
                      {{slotProps.data.recibidoFecha}}
                    </template>
                  </Column>

                </DataTable>
              </div>
            </AccordionTab>
            <AccordionTab header="Devolución">
              <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
                <h2 class="text-lg font-medium mr-auto">Devolución de mercancia</h2>
                <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
                  <button class="btn btn-primary shadow-md mr-2" @click="openNewNovedadSyscom('09','DEVOLUCION MERCANCIA')">Nuevo</button>
                </div>
              </div>
              <div class="intro-y box p-5 mt-5" v-if="service.info_orden_servicio">
                <DataTable :value="novedadDevMerc" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                           :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
                  <template #header>
                    <div class="table-header">
                      Información
                      <span class="p-input-icon-left">
                        <i class="pi pi-search"/>
                        <InputText v-model="filters['global'].value" placeholder="Search"/>
                      </span>
                    </div>
                  </template>

                  <template #empty>
                    Datos no disponibles.
                  </template>
                  <template #loading>
                    Cargando datos. Por favor espera.
                  </template>

                  <Column field="codNovedad" header="Código Novedad" :sortable="true">
                    <template #body="slotProps">
                      <span class="p-column-title">Código Novedad</span>
                      {{slotProps.data.codNovedad}}
                    </template>
                  </Column>
                  <Column field="novedad" header="Novedad" :sortable="true">
                    <template #body="slotProps">
                      <span class="p-column-title">Novedad</span>
                      {{slotProps.data.novedad}}
                    </template>
                  </Column>
                  <Column field="fechaNovedad" header="Fecha novedad" :sortable="true">
                    <template #body="slotProps">
                      <span class="p-column-title">Fecha novedad</span>
                      {{slotProps.data.fechaNovedad}}
                    </template>
                  </Column>
                  <Column field="horaNovedad" header="Hora novedad" :sortable="true">
                    <template #body="slotProps">
                      <span class="p-column-title">Hora novedad</span>
                      {{slotProps.data.horaNovedad}}
                    </template>
                  </Column>
                  <Column field="observacion" header="Observación" :sortable="true">
                    <template #body="slotProps">
                      <span class="p-column-title">Observación</span>
                      {{slotProps.data.observacion}}
                    </template>
                  </Column>

                </DataTable>
              </div>
            </AccordionTab>
          </Accordion>
        </Fieldset>
      </div>
      <div class="intro-y box p-5 mt-5" v-if="permisos.Admin || permisos.Operaciones">
        
        <Fieldset legend="Proceso devolución Contenedor Vacío" :toggleable="true">
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Programación de entrega</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewNovedadSyscom('10','PROGRAMACIÓN DE ENTREGA CONTENEDOR')">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_orden_servicio">
            <DataTable :value="novedadProgEnt" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="codNovedad" header="Código Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Código Novedad</span>
                  {{slotProps.data.codNovedad}}
                </template>
              </Column>
              <Column field="novedad" header="Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Novedad</span>
                  {{slotProps.data.novedad}}
                </template>
              </Column>
              <Column field="fechaNovedad" header="Fecha novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Fecha novedad</span>
                  {{slotProps.data.fechaNovedad}}
                </template>
              </Column>
              <Column field="horaNovedad" header="Hora novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Hora novedad</span>
                  {{slotProps.data.horaNovedad}}
                </template>
              </Column>
              <Column field="observacion" header="Sitio de entrega" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Sitio de entrega</span>
                  {{slotProps.data.observacion}}
                </template>
              </Column>

            </DataTable>
          </div>
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Devolución Contenedor</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewDevolucion_Contenedor">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_devolucion_contenedor">
            <DataTable :value="service.info_devolucion_contenedor" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="contenedorNumero" header="Número Contenedor" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Número Contenedor</span>
                  {{slotProps.data.contenedorNumero}}
                </template>
              </Column>
              <Column field="fechaEntregaVacio" header="Fecha Entrega Vacio" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Fecha Entrega Vacio</span>
                  {{slotProps.data.fechaEntregaVacio}}
                </template>
              </Column>
              <Column field="horaEntregaVacio" header="Hora Entrega Vacio" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Hora Entrega Vacio</span>
                  {{slotProps.data.horaEntregaVacio}}
                </template>
              </Column>

            </DataTable>
          </div>
        </Fieldset>
      </div>
    </div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8" v-if="service.info_novedad && (permisos.Admin || permisos.Operaciones)">
      <h2 class="text-lg font-medium mr-auto">Otras novedades</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="btn btn-primary shadow-md mr-2" @click="openNewNovedad('*')">Nuevo</button>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-12">
      <div class="intro-y box p-5 mt-5" v-if="service.info_novedad && (permisos.Admin || permisos.Operaciones)">
        <DataTable :value="novedadGeneral" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Información
              <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
            </div>
          </template>

          <template #empty>
            Datos no disponibles.
          </template>
          <template #loading>
            Cargando datos. Por favor espera.
          </template>

          <Column field="novedadNombre" header="Nombre novedad" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nombre novedad</span>
              {{slotProps.data.novedadNombre}}
            </template>
          </Column>
          <Column field="novedadFecha" header="Fecha" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Fecha</span>
              {{slotProps.data.novedadFecha}}
            </template>
          </Column>
          <Column field="novedadHora" header="Hora" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Hora</span>
              {{slotProps.data.novedadHora}}
            </template>
          </Column>
          <Column field="novedadObservacion" header="Observación" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Observación</span>
              {{slotProps.data.novedadObservacion}}
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
    <!---Trafico-->
    <div class="grid grid-cols-1 gap-12">
      <div class="intro-y box p-5 mt-5" v-if="permisos.Admin || permisos.Trafico">
        <Fieldset legend="Proceso de Cargue" :toggleable="true">
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Ubicación sitio de cargue</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewNovedadSyscom('01','COORDENADAS CARGUE')">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_orden_servicio">
            <DataTable :value="novedadCoord" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="codNovedad" header="Código Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Código Novedad</span>
                  {{slotProps.data.codNovedad}}
                </template>
              </Column>
              <Column field="novedad" header="Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Novedad</span>
                  {{slotProps.data.novedad}}
                </template>
              </Column>
              <Column field="latitudNovedad" header="Latitud novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Latitud novedad</span>
                  {{slotProps.data.latitudNovedad}}
                </template>
              </Column>
              <Column field="longitudNovedad" header="Longitud novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Longitud novedad</span>
                  {{slotProps.data.longitudNovedad}}
                </template>
              </Column>
              <Column field="observacion" header="Observación" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Observación</span>
                  {{slotProps.data.observacion}}
                </template>
              </Column>

            </DataTable>
          </div>
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Llegada Sitio de Cargue</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewNovedad('CARGUE')">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_novedad">
            <DataTable :value="novedadCargue" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="novedadNombre" header="Nombre novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Nombre novedad</span>
                  {{slotProps.data.novedadNombre}}
                </template>
              </Column>
              <Column field="novedadFecha" header="Fecha" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Fecha</span>
                  {{slotProps.data.novedadFecha}}
                </template>
              </Column>
              <Column field="novedadHora" header="Hora" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Hora</span>
                  {{slotProps.data.novedadHora}}
                </template>
              </Column>
              <Column field="novedadObservacion" header="Observación" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Observación</span>
                  {{slotProps.data.novedadObservacion}}
                </template>
              </Column>

            </DataTable>
          </div>
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">En Plataforma de cargue</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewNovedadSyscom('02','PLATAFORMA CARGUE')">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_orden_servicio">
            <DataTable :value="novedadPlataforma" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="codNovedad" header="Código Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Código Novedad</span>
                  {{slotProps.data.codNovedad}}
                </template>
              </Column>
              <Column field="novedad" header="Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Novedad</span>
                  {{slotProps.data.novedad}}
                </template>
              </Column>
              <Column field="fechaNovedad" header="Fecha novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Fecha novedad</span>
                  {{slotProps.data.fechaNovedad}}
                </template>
              </Column>
              <Column field="horaNovedad" header="Hora novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Hora novedad</span>
                  {{slotProps.data.horaNovedad}}
                </template>
              </Column>
              <Column field="observacion" header="Observación" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Observación</span>
                  {{slotProps.data.observacion}}
                </template>
              </Column>

            </DataTable>
          </div>
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Inicio de cargue</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewNovedadSyscom('03','INICIO CARGUE')">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_orden_servicio">
            <DataTable :value="novedadInicio" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="codNovedad" header="Código Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Código Novedad</span>
                  {{slotProps.data.codNovedad}}
                </template>
              </Column>
              <Column field="novedad" header="Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Novedad</span>
                  {{slotProps.data.novedad}}
                </template>
              </Column>
              <Column field="fechaNovedad" header="Fecha novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Fecha novedad</span>
                  {{slotProps.data.fechaNovedad}}
                </template>
              </Column>
              <Column field="horaNovedad" header="Hora novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Hora novedad</span>
                  {{slotProps.data.horaNovedad}}
                </template>
              </Column>
              <Column field="observacion" header="Observación" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Observación</span>
                  {{slotProps.data.observacion}}
                </template>
              </Column>

            </DataTable>
          </div>
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Fin de cargue</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewNovedadSyscom('04','FIN CARGUE')">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_orden_servicio">
            <DataTable :value="novedadFin" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="codNovedad" header="Código Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Código Novedad</span>
                  {{slotProps.data.codNovedad}}
                </template>
              </Column>
              <Column field="novedad" header="Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Novedad</span>
                  {{slotProps.data.novedad}}
                </template>
              </Column>
              <Column field="fechaNovedad" header="Fecha novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Fecha novedad</span>
                  {{slotProps.data.fechaNovedad}}
                </template>
              </Column>
              <Column field="horaNovedad" header="Hora novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Hora novedad</span>
                  {{slotProps.data.horaNovedad}}
                </template>
              </Column>
              <Column field="observacion" header="Observación" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Observación</span>
                  {{slotProps.data.observacion}}
                </template>
              </Column>

            </DataTable>
          </div>
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Salida del sitio de cargue</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewNovedadSyscom('05','SALIDA CARGUE')">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_orden_servicio">
            <DataTable :value="novedadSalida" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="codNovedad" header="Código Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Código Novedad</span>
                  {{slotProps.data.codNovedad}}
                </template>
              </Column>
              <Column field="novedad" header="Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Novedad</span>
                  {{slotProps.data.novedad}}
                </template>
              </Column>
              <Column field="fechaNovedad" header="Fecha novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Fecha novedad</span>
                  {{slotProps.data.fechaNovedad}}
                </template>
              </Column>
              <Column field="horaNovedad" header="Hora novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Hora novedad</span>
                  {{slotProps.data.horaNovedad}}
                </template>
              </Column>
              <Column field="observacion" header="Observación" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Observación</span>
                  {{slotProps.data.observacion}}
                </template>
              </Column>

            </DataTable>
          </div>
        </Fieldset>
      </div>
      <div class="intro-y box p-5 mt-5" v-if="permisos.Admin || permisos.Trafico">
        <Fieldset legend="Proceso Ruta" :toggleable="true">
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Inicio de ruta</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewNovedad('TRANSITO')">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_novedad">
            <DataTable :value="novedadTransito" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="novedadNombre" header="Nombre novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Nombre novedad</span>
                  {{slotProps.data.novedadNombre}}
                </template>
              </Column>
              <Column field="novedadFecha" header="Fecha" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Fecha</span>
                  {{slotProps.data.novedadFecha}}
                </template>
              </Column>
              <Column field="novedadHora" header="Hora" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Hora</span>
                  {{slotProps.data.novedadHora}}
                </template>
              </Column>
              <Column field="novedadObservacion" header="Observación" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Observación</span>
                  {{slotProps.data.novedadObservacion}}
                </template>
              </Column>

            </DataTable>
          </div>
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Puesto de control</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewControl">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_control">
            <DataTable :value="service.info_control" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="puestoControlNombre" header="Nombre puesto de control" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Nombre puesto de control</span>
                  {{slotProps.data.puestoControlNombre}}
                </template>
              </Column>
              <Column field="puestoControlFecha" header="Fecha" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Fecha</span>
                  {{slotProps.data.puestoControlFecha}}
                </template>
              </Column>
              <Column field="puestoControlHora" header="Hora" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Hora</span>
                  {{slotProps.data.puestoControlHora}}
                </template>
              </Column>
              <Column field="puestoControlLatitud" header="Latitud" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Latitud</span>
                  {{slotProps.data.puestoControlLatitud}}
                </template>
              </Column>
              <Column field="puestoControlLongitud" header="Longitud" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Longitud</span>
                  {{slotProps.data.puestoControlLongitud}}
                </template>
              </Column>
              <Column field="puestoControlObservacion" header="Observación" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Observación</span>
                  {{slotProps.data.puestoControlObservacion}}
                </template>
              </Column>

            </DataTable>
          </div>
        </Fieldset>
      </div>
      <div class="intro-y box p-5 mt-5" v-if="permisos.Admin || permisos.Trafico">
        <Fieldset legend="Proceso Descargue" :toggleable="true">
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Ubicación sitio de descargue</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewNovedadSyscom('06','COORDENADAS DESCARGUE')">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_orden_servicio">
            <DataTable :value="novedadCoordDesc" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="codNovedad" header="Código Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Código Novedad</span>
                  {{slotProps.data.codNovedad}}
                </template>
              </Column>
              <Column field="novedad" header="Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Novedad</span>
                  {{slotProps.data.novedad}}
                </template>
              </Column>
              <Column field="latitudNovedad" header="Latitud novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Latitud novedad</span>
                  {{slotProps.data.latitudNovedad}}
                </template>
              </Column>
              <Column field="longitudNovedad" header="Longitud novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Longitud novedad</span>
                  {{slotProps.data.longitudNovedad}}
                </template>
              </Column>
              <Column field="observacion" header="Observación" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Observación</span>
                  {{slotProps.data.observacion}}
                </template>
              </Column>

            </DataTable>
          </div>
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Llegada Sitio de Descargue</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewLlegada_sitio">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_llegada_sitio">
            <DataTable :value="service.info_llegada_sitio" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="itemEntrega" header="Item Entrega" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Item Entrega</span>
                  {{slotProps.data.itemEntrega}}
                </template>
              </Column>
              <Column field="LLegadaSitioFecha" header="Fecha" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Fecha</span>
                  {{slotProps.data.LLegadaSitioFecha}}
                </template>
              </Column>
              <Column field="LLegadaSitioHora" header="Hora" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Hora</span>
                  {{slotProps.data.LLegadaSitioHora}}
                </template>
              </Column>
              <Column field="LlegadaSitioObservacion" header="Observación" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Observación</span>
                  {{slotProps.data.LlegadaSitioObservacion}}
                </template>
              </Column>

            </DataTable>
          </div>
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">En Plataforma de descargue</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewNovedadSyscom('07','PLATAFORMA DESCARGUE')">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_orden_servicio">
            <DataTable :value="novedadPlataformaDesc" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="codNovedad" header="Código Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Código Novedad</span>
                  {{slotProps.data.codNovedad}}
                </template>
              </Column>
              <Column field="novedad" header="Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Novedad</span>
                  {{slotProps.data.novedad}}
                </template>
              </Column>
              <Column field="fechaNovedad" header="Fecha novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Fecha novedad</span>
                  {{slotProps.data.fechaNovedad}}
                </template>
              </Column>
              <Column field="horaNovedad" header="Hora novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Hora novedad</span>
                  {{slotProps.data.horaNovedad}}
                </template>
              </Column>
              <Column field="observacion" header="Observación" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Observación</span>
                  {{slotProps.data.observacion}}
                </template>
              </Column>

            </DataTable>
          </div>
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Inicio de Descargue</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewNovedad('DESCARGUE')">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_novedad">
            <DataTable :value="novedadDescargue" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="novedadNombre" header="Nombre novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Nombre novedad</span>
                  {{slotProps.data.novedadNombre}}
                </template>
              </Column>
              <Column field="novedadFecha" header="Fecha" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Fecha</span>
                  {{slotProps.data.novedadFecha}}
                </template>
              </Column>
              <Column field="novedadHora" header="Hora" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Hora</span>
                  {{slotProps.data.novedadHora}}
                </template>
              </Column>
              <Column field="novedadObservacion" header="Observación" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Observación</span>
                  {{slotProps.data.novedadObservacion}}
                </template>
              </Column>

            </DataTable>
          </div>
          <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 class="text-lg font-medium mr-auto">Fin de descargue</h2>
            <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <button class="btn btn-primary shadow-md mr-2" @click="openNewNovedadSyscom('08','FIN DESCARGUE')">Nuevo</button>
            </div>
          </div>
          <div class="intro-y box p-5 mt-5" v-if="service.info_orden_servicio">
            <DataTable :value="novedadFinDesc" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                       :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
              <template #header>
                <div class="table-header">
                  Información
                  <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
                </div>
              </template>

              <template #empty>
                Datos no disponibles.
              </template>
              <template #loading>
                Cargando datos. Por favor espera.
              </template>

              <Column field="codNovedad" header="Código Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Código Novedad</span>
                  {{slotProps.data.codNovedad}}
                </template>
              </Column>
              <Column field="novedad" header="Novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Novedad</span>
                  {{slotProps.data.novedad}}
                </template>
              </Column>
              <Column field="fechaNovedad" header="Fecha novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Fecha novedad</span>
                  {{slotProps.data.fechaNovedad}}
                </template>
              </Column>
              <Column field="horaNovedad" header="Hora novedad" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Hora novedad</span>
                  {{slotProps.data.horaNovedad}}
                </template>
              </Column>
              <Column field="observacion" header="Observación" :sortable="true">
                <template #body="slotProps">
                  <span class="p-column-title">Observación</span>
                  {{slotProps.data.observacion}}
                </template>
              </Column>

            </DataTable>
          </div>
        </Fieldset>
      </div>
    </div>
    <!--- Otm -->
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8" v-if="service.info_cierre_otm && (permisos.Admin || permisos.Otm)">
      <h2 class="text-lg font-medium mr-auto" v-if="service.active == 1">Cierre Otm</h2>
      <h2 class="text-lg font-medium mr-auto" v-if="service.active == 2">Administración de cargas pendientes</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button class="btn btn-primary shadow-md mr-2" @click="openNewCierreOTM">Nuevo</button>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-12">
      <!-- Cierre OTM -->
      <div class="intro-y box p-5 mt-5" v-if="service.active == 1 && service.info_cierre_otm && (permisos.Admin || permisos.Otm)">
        <DataTable :value="service.info_cierre_otm" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Información
              <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
            </div>
          </template>

          <template #empty>
            Datos no disponibles.
          </template>
          <template #loading>
            Cargando datos. Por favor espera.
          </template>

          <Column field="fechaCierreOtm" header="Fecha" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Fecha</span>
              {{slotProps.data.fechaCierreOtm}}
            </template>
          </Column>
          <Column field="horaCierreOtm" header="Hora" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Hora</span>
              {{slotProps.data.horaCierreOtm}}
            </template>
          </Column>
          <Column field="itemEntrega" header="Item Entrega" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Item Entrega</span>
              {{slotProps.data.itemEntrega}}
            </template>
          </Column>

        </DataTable>
      </div>
      <!-- Administración de cargas pendientes -->
      <div class="intro-y box p-5 mt-5" v-if="service.active == 2 && service.info_cierre_otm && (permisos.Admin || permisos.Otm)">
        <DataTable :value="service.info_cierre_otm" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Información
              <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
            </div>
          </template>

          <template #empty>
            Datos no disponibles.
          </template>
          <template #loading>
            Cargando datos. Por favor espera.
          </template>

          <Column field="fechaCierreOtm" header="Fecha" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Fecha</span>
              {{slotProps.data.fechaCierreOtm}}
            </template>
          </Column>
          <Column field="horaCierreOtm" header="Hora" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Hora</span>
              {{slotProps.data.horaCierreOtm}}
            </template>
          </Column>
          <Column field="fechaInicio" header="Fecha inicio" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Fecha inicio</span>
              {{slotProps.data.fechaInicio}}
            </template>
          </Column>
          <Column field="fechaFin" header="Fecha fin" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Fecha fin</span>
              {{slotProps.data.fechaFin}}
            </template>
          </Column>
          <Column field="itemEntrega" header="Novedad" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Novedad</span>
              {{slotProps.data.itemEntrega}}
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
    <Dialog v-model:visible="adicionalDialog" :style="{width: '450px'}" header="Servicios adicionales" :modal="true" class="p-fluid">
      <div class="p-field">
        <label for="itemServicioAdicional">Item</label>
        <InputText type="number" id="itemServicioAdicional" v-model.trim="adicional.itemServicioAdicional" required="true"  />
      </div>
      <div class="p-field">
        <label for="servicioAdicionalModal">Servicio adicional</label>
        <Dropdown id="servicioAdicionalModal" v-model="adicional.servicioAdicional" :filter="true" :options="optionsAdicional" optionLabel="name" placeholder="Select" />
      </div>
      <div class="p-field">
        <label for="valorModal">Valor</label>
        <InputText id="valorModal" v-model.trim="adicional.valor" required="true"  />
      </div>
      <div class="p-field">
        <label for="cantidadModal">Cantidad</label>
        <InputText type="number" id="cantidadModal" v-model.trim="adicional.cantidad" required="true"  />
      </div>
      <div class="p-field">
        <label for="valorTotalModal">Valor total</label>
        <InputText  id="valorTotalModal" v-model.trim="adicional.valorTotal" required="true"  />
      </div>
      <div class="p-field">
        <label for="valorTotalMonedaModal">Tipo de moneda</label>
        <InputText  id="valorTotalMonedaModal" v-model.trim="adicional.valorTotalMoneda" required="true"  />
      </div>
      <div class="p-field">
        <label for="observacionEmpresaModal">Observación</label>
        <Textarea id="observacionEmpresaModal" v-model="adicional.observacionEmpresa" required="true"  rows="3" cols="20" />
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveAdicional">Aceptar Solicitud</button>
      </template>
    </Dialog>
    <Dialog v-model:visible="despachoDialog" :style="{width: '450px'}" header="Cargue Despacho" :modal="true" class="p-fluid">
      <div class="p-field">
        <label for="solicitudServicioDesp">Solicitud de servicio</label>
        <InputText type="text" id="solicitudServicioDesp" v-model.trim="despacho.solicitudServicio" required="true"  readonly="true" />
      </div>
      <div class="p-field">
        <label for="vehiculoDesp">Placa Vehículo</label>
        <InputText type="text" id="vehiculoDesp" v-model.trim="despacho.vehiculo" required="true"  />
      </div>
      <div class="p-field">
        <label for="vehiculoModelo">Modelo vehículo</label>
        <InputText id="vehiculoModelo" v-model.trim="despacho.vehiculoModelo" required="true"  />
      </div>
      <div class="p-field">
        <label for="conductorNombre">Nombre condutor</label>
        <InputText type="text" id="conductorNombre" v-model.trim="despacho.conductorNombre" required="true"  />
      </div>
      <div class="p-field">
        <label for="conductorDocumento">Documento conductor</label>
        <InputText  id="conductorDocumento" v-model.trim="despacho.conductorDocumento" required="true"  />
      </div>
      <div class="p-field">
        <label for="conductorCelular">Celular conductor</label>
        <InputText  id="conductorCelular" v-model.trim="despacho.conductorCelular" required="true"  />
      </div>
      <div class="p-field">
        <label for="claseVehiculoDesp">Clase vehículo</label>
        <InputText  id="claseVehiculoDesp" v-model.trim="despacho.claseVehiculo" required="true"  />
      </div>
      <div class="p-field">
        <label for="fechaInicioCargue">Fecha de incio cargue</label>
        <InputText type="date" id="fechaInicioCargue" v-model.trim="despacho.fechaInicioCargue" required="true"  />
      </div>
      <div class="p-field">
        <label for="horaInicioCargue">Hora de incio cargue</label>
        <InputText type="time" step="2" id="horaInicioCargue" v-model.trim="despacho.horaInicioCargue" required="true"  />
      </div>
      <div class="p-field">
        <label for="fechaFinCargue">Fecha de fin cargue</label>
        <InputText type="date" id="fechaFinCargue" v-model.trim="despacho.fechaFinCargue" required="true"  />
      </div>
      <div class="p-field">
        <label for="horaFinCargue">Hora de fin cargue</label>
        <InputText type="time" step="2" id="horaFinCargue" v-model.trim="despacho.horaFinCargue" required="true"  />
      </div>
      <div class="p-field">
        <label for="precinto">Precinto</label>
        <InputText id="precinto" v-model.trim="despacho.precinto" required="true"  />
      </div>
      <div class="p-field">
        <label for="remesa">Remesa</label>
        <InputText id="remesa" v-model.trim="despacho.remesa" required="true"  />
      </div>
      <div class="p-field">
        <label for="manifiesto">Manifiesto</label>
        <InputText id="manifiesto" v-model.trim="despacho.manifiesto" required="true"  />
      </div>
      <div class="p-field">
        <label for="fechaRemesa">Fecha Remesa</label>
        <InputText type="date" id="fechaRemesa" v-model.trim="despacho.fechaRemesa" required="true"  />
      </div>
      <div class="p-field">
        <label for="fechaManifiesto">Fecha Manifiesto</label>
        <InputText type="date" id="fechaManifiesto" v-model.trim="despacho.fechaManifiesto" required="true"  />
      </div>
      <div class="p-field">
        <label for="ordenCargue">Orden Cargue</label>
        <InputText id="ordenCargue" v-model.trim="despacho.ordenCargue" required="true"  />
      </div>
      <div class="p-field">
        <label for="ordenCargueFechaCreacion">Fecha Orden Cargue</label>
        <InputText type="date" id="ordenCargueFechaCreacion" v-model.trim="despacho.ordenCargueFechaCreacion" required="true"  />
      </div>
      <div class="p-field">
        <label for="fechaRecepcionDocumentosCargue">Fecha Recepcion Documentos Cargue</label>
        <InputText type="date" id="fechaRecepcionDocumentosCargue" v-model.trim="despacho.fechaRecepcionDocumentosCargue" required="true"  />
      </div>
      <div class="p-field">
        <label for="horaRecepcionDocumentosCargue">Hora Recepcion Documentos Cargue</label>
        <InputText type="time" step="2" id="horaRecepcionDocumentosCargue" v-model.trim="despacho.horaRecepcionDocumentosCargue" required="true"  />
      </div>
      <div class="p-field">
        <label for="fechaSalidaRuta">Fecha Salida Ruta</label>
        <InputText type="date" id="fechaSalidaRuta" v-model.trim="despacho.fechaSalidaRuta" required="true"  />
      </div>
      <div class="p-field">
        <label for="horaRecepcionDocumentosCargue">Hora Salida Ruta</label>
        <InputText type="time" step="2" id="horaSalidaRuta" v-model.trim="despacho.horaSalidaRuta" required="true"  />
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveDespacho">Aceptar Solicitud</button>
      </template>
    </Dialog>
    <Dialog v-model:visible="Devolucion_ContenedorDialog" :style="{width: '450px'}" header="Devolución Contenedor" :modal="true" class="p-fluid">
      <div class="p-field">
        <label for="solicitudServicioDev">Solicitud de servicio</label>
        <InputText type="text" id="solicitudServicioDev" v-model.trim="Devolucion_Contenedor.solicitudServicio" required="true" readonly="true"  />
      </div>
      <div class="p-field">
        <label for="contenedorNumeroDev">Numero de Contenedor</label>
        <Dropdown id="contenedorNumeroDev" v-model="Devolucion_Contenedor.contenedorNumero" :filter="true" :options="optionsContenedor" optionLabel="contenedorNumero" placeholder="Select" />
      </div>
      <div class="p-field">
        <label for="fechaEntregaVacio">Fecha de devolucion</label>
        <InputText type="date" id="fechaEntregaVacio" v-model.trim="Devolucion_Contenedor.fechaEntregaVacio" required="true"  />
      </div>
      <div class="p-field">
        <label for="horaEntregaVacio">Hora de Devoluciion</label>
        <InputText type="time" step="2" id="horaEntregaVacio" v-model.trim="Devolucion_Contenedor.horaEntregaVacio" required="true"  />
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveDevolucion_Contenedor">Aceptar Solicitud</button>
      </template>
    </Dialog>
    <Dialog v-model:visible="Entrega_MercanciaDialog" :style="{width: '450px'}" header="Entrega Mercancia" :modal="true" class="p-fluid">
        <div class="p-field">
          <label for="solicitudServicioEnt">Solicitud de servicio</label>
          <InputText type="text" id="solicitudServicioEnt" v-model.trim="Entrega_Mercancia.solicitudServicio" required="true" readonly="true"  />
        </div>
        <div class="p-field">
          <label for="itemEntrega">Item de entrega</label>
          <InputText type="number" id="itemEntrega" v-model.trim="Entrega_Mercancia.itemEntrega" required="true"  />
        </div>
        <div class="p-field">
          <label for="recibidoNombre">Nombre de quien recibe</label>
          <InputText type="text" id="recibidoNombre" v-model.trim="Entrega_Mercancia.recibidoNombre" required="true"  />
        </div>
        <div class="p-field">
          <label for="recibidoCelular">Celular de quien recibe</label>
          <InputText type="text" id="recibidoCelular" v-model.trim="Entrega_Mercancia.recibidoCelular" required="true"  />
        </div>
        <div class="p-field">
          <label for="recibidoFecha">Fecha de recibido</label>
          <InputText type="date" id="recibidoFecha" v-model.trim="Entrega_Mercancia.recibidoFecha" required="true"  />
        </div>
        <div class="p-field">
          <label for="recibidoHora">Hora de recibido</label>
          <InputText type="time" step="2" id="recibidoHora" v-model.trim="Entrega_Mercancia.recibidoHora" required="true"  />
        </div>

        <template #footer>
          <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveEntrega_Mercancia">Aceptar Solicitud</button>
        </template>
    </Dialog>
    <Dialog v-model:visible="Llegada_sitioDialog" :style="{width: '450px'}" header="Llegada sitio" :modal="true" class="p-fluid">
      <div class="p-field">
        <label for="solicitudServicioLleg">Solicitud de servicio</label>
        <InputText type="text" id="solicitudServicioLleg" v-model.trim="Llegada_sitio.solicitudServicio" required="true" readonly="true"  />
      </div>
      <div class="p-field">
        <label for="itemEntregaLleg">Item de entrega</label>
        <InputText type="number" id="itemEntregaLleg" v-model.trim="Llegada_sitio.itemEntrega" required="true" />
      </div>
      <div class="p-field">
        <label for="LLegadaSitioFecha">Fecha de llegada a sitio</label>
        <InputText type="date" id="LLegadaSitioFecha" v-model.trim="Llegada_sitio.LLegadaSitioFecha" required="true"  />
      </div>
      <div class="p-field">
        <label for="LLegadaSitioHora">Hora de llegada a sitio</label>
        <InputText type="time" step="2" id="LLegadaSitioHora" v-model.trim="Llegada_sitio.LLegadaSitioHora" required="true"  />
      </div>
      <div class="p-field">
        <label for="LlegadaSitioObservacion">Observación de llegada a sitio</label>
        <Textarea id="LlegadaSitioObservacion" v-model="Llegada_sitio.LlegadaSitioObservacion" required="true"  rows="3" cols="20" />
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveLlegada_sitio">Aceptar Solicitud</button>
      </template>
    </Dialog>
    <Dialog v-model:visible="ControlDialog" :style="{width: '450px'}" header="Puesto Control" :modal="true" class="p-fluid">
      <div class="p-field">
        <label for="solicitudServicioCont">Solicitud de servicio</label>
        <InputText type="text" id="solicitudServicioCont" v-model.trim="Control.solicitudServicio" required="true" readonly="true" />
      </div>
      <div class="p-field">
        <label for="puestoControlNombre">Nombre del puesto de control</label>
        <InputText type="text" id="puestoControlNombre" v-model.trim="Control.puestoControlNombre" required="true"  />
      </div>
      <div class="p-field">
        <label for="puestoControlFecha">Fecha llegada a puesto de control</label>
        <InputText type="date" id="puestoControlFecha" v-model.trim="Control.puestoControlFecha" required="true"  />
      </div>
      <div class="p-field">
        <label for="puestoControlHora">Hora llegada a puesto de control</label>
        <InputText type="time" step="2" id="puestoControlHora" v-model.trim="Control.puestoControlHora" required="true"  />
      </div>
      <div class="p-field">
        <label for="puestoControlLatitud">Latitud del puesto de control</label>
        <InputText type="text" id="puestoControlLatitud" v-model.trim="Control.puestoControlLatitud" required="true"  />
      </div>
      <div class="p-field">
        <label for="puestoControlLongitud">Longitud del puesto de control</label>
        <InputText type="text" id="puestoControlLongitud" v-model.trim="Control.puestoControlLongitud" required="true"  />
      </div>
      <div class="p-field">
        <label for="puestoControlObservacion">Observacion del puesto de control</label>
        <Textarea id="puestoControlObservacion" v-model="Control.puestoControlObservacion" required="true"  rows="3" cols="20" />
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveControl">Aceptar Solicitud</button>
      </template>
    </Dialog>
    <Dialog v-model:visible="NovedadDialog" :style="{width: '450px'}" header="Novedad" :modal="true" class="p-fluid">
      <div class="p-field">
        <label for="solicitudServicioNov">Solicitud de servicio</label>
        <InputText type="text" id="solicitudServicioNov" v-model.trim="Novedad.solicitudServicio" required="true" readonly="true" />
      </div>
      <div class="p-field">
        <label for="novedadNombre">Nombre de la novedad</label>
        <InputText type="text" id="novedadNombre" v-model.trim="Novedad.novedadNombre" required="true" readonly="true" />
      </div>
      <div class="p-field">
        <label for="novedadFecha">Fecha de la novedad</label>
        <InputText type="date" id="novedadFecha" v-model.trim="Novedad.novedadFecha" required="true"  />
      </div>
      <div class="p-field">
        <label for="novedadHora">Hora de la novedad</label>
        <InputText type="time" step="2" id="novedadHora" v-model.trim="Novedad.novedadHora" required="true"  />
      </div>
      <div class="p-field">
        <label for="novedadObservacion">Observacion de la novedad</label>
        <Textarea id="novedadObservacion" v-model="Novedad.novedadObservacion" required="true"  rows="3" cols="20" />
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveNovedad">Aceptar Solicitud</button>
      </template>
    </Dialog>
    <Dialog v-model:visible="auxiliarDialog" :style="{width: '450px'}" header="Auxiliar" :modal="true" class="p-fluid">

      <div class="p-field">
        <label for="solicitudServicioAux">Solicitud de servicio</label>
        <InputText type="text" id="solicitudServicioAux" v-model.trim="auxiliar.solicitudServicio" required="true" readonly="true" />
      </div>
      <div class="p-field">
        <label for="auxiliarNombre">Nombre del Auxiliar</label>
        <InputText type="text" id="auxiliarNombre" v-model.trim="auxiliar.auxiliarNombre" required="true"  />
      </div>
      <div class="p-field">
        <label for="auxiliarDocumento">Documento del Auxiliar</label>
        <InputText type="text" id="auxiliarDocumento" v-model.trim="auxiliar.auxiliarDocumento" required="true"  />
      </div>
      <div class="p-field">
        <label for="auxiliarTelefono">Telefono del Auxiliar</label>
        <InputText type="text" id="auxiliarTelefono" v-model.trim="auxiliar.auxiliarTelefono" required="true"  />
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveAuxiliar">Aceptar Solicitud</button>
      </template>
    </Dialog>
    <Dialog v-model:visible="cambioVehiculoDialog" :style="{width: '450px'}" header="Cambio Vehículo" :modal="true" class="p-fluid">

      <div class="p-field">
        <label for="solicitudServicioVeh">Solicitud de servicio</label>
        <InputText type="text" id="solicitudServicioVeh" v-model.trim="cambioVehiculo.solicitudServicio" required="true"  />
      </div>
      <div class="p-field">
        <label for="vehiculo">Placa de vehiculo</label>
        <InputText type="text" id="vehiculo" v-model.trim="cambioVehiculo.vehiculo" required="true"  />
      </div>
      <div class="p-field">
        <label for="vehiculoModeloVeh">Modelo del vehiculo</label>
        <InputText type="text" id="vehiculoModeloVeh" v-model.trim="cambioVehiculo.vehiculoModelo" required="true"  />
      </div>
      <div class="p-field">
        <label for="conductorNombreVeh">Nombre del conductor</label>
        <InputText type="text" id="conductorNombreVeh" v-model.trim="cambioVehiculo.conductorNombre" required="true"  />
      </div>
      <div class="p-field">
        <label for="conductorDocumentoVeh">Documento del conductor</label>
        <InputText type="text" id="conductorDocumentoVeh" v-model.trim="cambioVehiculo.conductorDocumento" required="true"  />
      </div>
      <div class="p-field">
        <label for="conductorCelularVeh">Celular del conductor</label>
        <InputText type="text" id="conductorCelularVeh" v-model.trim="cambioVehiculo.conductorCelular" required="true"  />
      </div>
      <div class="p-field">
        <label for="observacionVeh">Observación</label>
        <InputText type="text" id="observacionVeh" v-model.trim="cambioVehiculo.observacion" required="true"  />
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveCambioVehiculo">Aceptar Solicitud</button>
      </template>
    </Dialog>
    <Dialog v-model:visible="facturaDialog" :style="{width: '450px'}" header="Factura" :modal="true" class="p-fluid">
      <div class="p-field">
        <label for="solicitudServicio">Solicitud de servicio</label>
        <InputText type="text" id="solicitudServicio" v-model.trim="factura.solicitudServicio" required="true"  readonly="true" />
      </div>
      <div class="p-field">
        <label for="numerofactura">Numero de factura</label>
        <InputText type="text" id="numerofactura" v-model.trim="factura.numerofactura" required="true"  />
      </div>
      <div class="p-field">
        <label for="fechafactura">Fecha cierre factura</label>
        <InputText type="date" id="fechafactura" v-model.trim="factura.fechafactura" required="true"  />
      </div>
      <div class="p-field">
        <label for="valorfactura">Valor de la factura</label>
        <InputText type="text" id="valorfactura" v-model.trim="factura.valorfactura" required="true"  />
      </div>
      <div class="p-field">
        <label for="monedafactura">Moneda de la factura</label>
        <InputText type="text" id="monedafactura" v-model.trim="factura.monedafactura" required="true"  />
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveFactura">Aceptar Solicitud</button>
      </template>
    </Dialog>
    <Dialog v-model:visible="cierreOTMDialog" :style="{width: '450px'}" header="Información" :modal="true" class="p-fluid">
      <div class="p-field">
        <label for="solicitudServicioOtm">Solicitud de servicio</label>
        <InputText type="text" id="solicitudServicioOtm" v-model.trim="cierreOTM.solicitudServicio" required="true" readonly="true" />
      </div>
      <div class="p-field">
        <label for="itemEntregaOtm" v-if="service.active == 1">Item de entrega</label>
        <label for="itemEntregaOtm" v-if="service.active == 2">Novedad</label>
        <InputText type="text" id="itemEntregaOtm" v-model.trim="cierreOTM.itemEntrega" required="true"  />
      </div>
      <div class="p-field">
        <label for="fechaCierreOtm">Fecha creación</label>
        <InputText type="date" id="fechaCierreOtm" v-model.trim="cierreOTM.fechaCierreOtm" required="true"  />
      </div>
      <div class="p-field">
        <label for="horaCierreOtm">Hora creación</label>
        <InputText type="time" step="2" id="horaCierreOtm" v-model.trim="cierreOTM.horaCierreOtm" required="true"  />
      </div>
      <div class="p-field" v-if="service.active == 2">
        <label for="fechaInicio">Fecha inicio</label>
        <InputText type="date" id="fechaInicio" v-model.trim="fechaInicioOtm" required="true"  />
      </div>
      <div class="p-field" v-if="service.active == 2">
        <label for="fechaFin">Fecha fin</label>
        <InputText type="date" id="fechaFin" v-model.trim="fechaFinOtm" required="true"  />
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveCierreOTM">Guardar</button>
      </template>
    </Dialog>
    <Dialog v-model:visible="NovedadDialogCoord" :style="{width: '450px'}" header="Novedades" :modal="true" class="p-fluid">
      <div class="p-field">
        <label for="codNovedad">Código Novedad</label>
        <InputText type="number" id="codNovedad" v-model.trim="novedadSyscom.codNovedad" required="true" readonly="true" />
      </div>
      <div class="p-field">
        <label for="novedad">Novedad</label>
        <InputText id="novedad" v-model.trim="novedadSyscom.novedad" required="true" readonly="true" />
      </div>
      <div class="p-field">
        <label for="fechaNovedad">Fecha de la novedad</label>
        <InputText type="date" id="fechaNovedad" v-model.trim="novedadSyscom.fechaNovedad" required="true"  />
      </div>
      <div class="p-field">
        <label for="horaNovedad">Hora de la novedad</label>
        <InputText type="time" step="2" id="horaNovedad" v-model.trim="novedadSyscom.horaNovedad" required="true"  />
      </div>
      <div class="p-field">
        <label for="latitudNovedad">Latitud de la novedad</label>
        <InputText type="text" id="latitudNovedad" v-model.trim="novedadSyscom.latitudNovedad" required="true"  />
      </div>
      <div class="p-field">
        <label for="longitudNovedad">Longitud de la novedad</label>
        <InputText type="text" id="longitudNovedad" v-model.trim="novedadSyscom.longitudNovedad" required="true"  />
      </div>
      <div class="p-field">
        <label for="observacionNov">Observación</label>
        <Textarea id="observacionNov" v-model="novedadSyscom.observacion" required="true"  rows="3" cols="20" />
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveNovedadSyscom">Aceptar Novedad</button>
      </template>
    </Dialog>
    <Dialog v-model:visible="NovedadDialogSyscom" :style="{width: '450px'}" header="Novedades" :modal="true" class="p-fluid">
      <div class="p-field">
        <label for="codNovedadSys">Código Novedad</label>
        <InputText type="number" id="codNovedadSys" v-model.trim="novedadSyscom.codNovedad" required="true" readonly="true" />
      </div>
      <div class="p-field">
        <label for="novedadSys">Novedad</label>
        <InputText id="novedadSys" v-model.trim="novedadSyscom.novedad" required="true" readonly="true" />
      </div>
      <div class="p-field">
        <label for="fechaNovedadSys">Fecha de la novedad</label>
        <InputText type="date" id="fechaNovedadSys" v-model.trim="novedadSyscom.fechaNovedad" required="true"  />
      </div>
      <div class="p-field">
        <label for="horaNovedadSys">Hora de la novedad</label>
        <InputText type="time" step="2" id="horaNovedadSys" v-model.trim="novedadSyscom.horaNovedad" required="true"  />
      </div>
      <div class="p-field">
        <label for="observacionNovSys">Observación</label>
        <Textarea id="observacionNovSys" v-model="novedadSyscom.observacion" required="true"  rows="3" cols="20" />
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveNovedadSyscom">Aceptar Novedad</button>
      </template>
    </Dialog>
    <Dialog v-model:visible="NovedadDialogSyscomOtro" :style="{width: '450px'}" header="Novedades" :modal="true" class="p-fluid">
      <div class="p-field">
        <label for="codNovedadSysOtro">Código Novedad</label>
        <InputText type="number" id="codNovedadSysOtro" v-model.trim="novedadSyscom.codNovedad" required="true" readonly="true" />
      </div>
      <div class="p-field">
        <label for="novedadSysOtro">Novedad</label>
        <InputText id="novedadSysOtro" v-model.trim="novedadSyscom.novedad" required="true" readonly="true" />
      </div>
      <div class="p-field">
        <label for="fechaNovedadSys">Fecha de la novedad</label>
        <InputText type="date" id="fechaNovedadSysOtro" v-model.trim="novedadSyscom.fechaNovedad" required="true"  />
      </div>
      <div class="p-field">
        <label for="horaNovedadSys">Hora de la novedad</label>
        <InputText type="time" step="2" id="horaNovedadSysOtro" v-model.trim="novedadSyscom.horaNovedad" required="true"  />
      </div>
      <div class="p-field">
        <label for="observacionNovSysOtro">Sitio de entrega</label>
        <Textarea id="observacionNovSysOtro" v-model="novedadSyscom.observacion" required="true"  rows="3" cols="20" />
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveNovedadSyscom">Aceptar Novedad</button>
      </template>
    </Dialog>
    <Dialog v-model:visible="NovedadDialogSelect" :style="{width: '450px'}" header="Novedad" :modal="true" class="p-fluid">
      <div class="p-field">
        <label for="solicitudServicioSel">Solicitud de servicio</label>
        <InputText type="text" id="solicitudServicioSel" v-model.trim="Novedad.solicitudServicio" required="true" readonly="true" />
      </div>
      <div class="p-field">
        <label for="novedadNombreSel">Nombre de la novedad</label>
        <Dropdown id="novedadNombreSel" v-model="Novedad.novedadNombre" :filter="true" :options="optionsNovedad" placeholder="Select" />
      </div>
      <div class="p-field">
        <label for="novedadFechaSel">Fecha de la novedad</label>
        <InputText type="date" id="novedadFechaSel" v-model.trim="Novedad.novedadFecha" required="true"  />
      </div>
      <div class="p-field">
        <label for="novedadHoraSel">Hora de la novedad</label>
        <InputText type="time" step="2" id="novedadHoraSel" v-model.trim="Novedad.novedadHora" required="true"  />
      </div>
      <div class="p-field">
        <label for="novedadObservacionSel">Observacion de la novedad</label>
        <Textarea id="novedadObservacionSel" v-model="Novedad.novedadObservacion" required="true"  rows="3" cols="20" />
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveNovedad">Aceptar Novedad</button>
      </template>
    </Dialog>
    <Dialog v-model:visible="vehiculoDialog" :style="{width: '450px'}" header="Asignar Vehículo" :modal="true" class="p-fluid">
      <div class="p-field">
        <label for="placa">Placa</label>
        <InputText id="placa" v-model="vehiculoItem.placa" type="text" required="true"/>
      </div>
      <div class="p-field">
        <label for="idCond">Identificación Conductor</label>
        <InputText id="idCond" v-model="vehiculoItem.idCond" type="text" required="true"/>
      </div>
      <div class="p-field">
        <label for="nombreCond">Nombre Conductor</label>
        <InputText id="nombreCond" v-model="vehiculoItem.nombreCond" type="text" required="true"/>
      </div>
      <div class="p-field">
        <label for="empSatelital">Empresa Satelital</label>
        <InputText id="empSatelital" v-model="vehiculoItem.empSatelital" type="text" required="true"/>
      </div>
      <div class="p-field">
        <label for="usuarioSatelital">Usuario Satelital</label>
        <InputText id="usuarioSatelital" v-model="vehiculoItem.usuarioSatelital" type="text" required="true"/>
      </div>
      <div class="p-field">
        <label for="claveSatelital">Clave Satelital</label>
        <InputText id="claveSatelital" v-model="vehiculoItem.claveSatelital" type="text" required="true"/>
      </div>
      <div class="p-field">
        <label for="urlSatelital">Coordenadas en Grados decimales</label>
        <InputText id="urlSatelital" v-model="vehiculoItem.urlSatelital" type="text" required="true"/>
      </div>

      <template #footer>
        <button class="btn btn-outline-primary w-24 inline-block mr-1 mb-2" @click="saveVehiculo">Asignar vehículo</button>
      </template>
    </Dialog>
  </div>
</template>
<script>
import AcceptedOrder from '../service/AcceptedOrder';
import axios from "axios";
import {API_BASE_URL, API_BASE_BDP_URL, API_LOCAL_URL} from "@/config";
import {FilterMatchMode} from "primevue/api";

export default {
  data() {
    return {
      permisos: {
        Admin: false,
        Panalpina: false,
        Bdp: false,
        Otros: false,
        Operaciones: false,
        Trafico: false,
        Otm: false
      },
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
      loading: true,
      selectedServices: null,
      service: {},
      idOrderService: null,
      vehiculoItem: {
        'placa':null,
        'idCond':null,
        'nombreCond':null,
        'observacion':null,
        'empSatelital':null,
        'usuarioSatelital':null,
        'claveSatelital':null,
        'urlSatelital':null,
        "solicitud":null,
      },
      adicional: {
        "itemServicioAdicional": null,
        "servicioAdicional": null,
        "valor": null,
        "cantidad": null,
        "valorTotal": null,
        "valorTotalMoneda": null,
        "observacionEmpresa": null,
        "idEntrega": null
      },
      adicionalOtro: {
        "solicitudServicio":null,
        "itemEntrega":null,
        "servicioAdicionalCodigo":null,
        "cantidad":null,
        "valor":null,
        "valorTotal":null,
        "observacion":null
      },
      despacho: {
        "solicitudServicio":null,
        "vehiculo":null,
        "vehiculoModelo":null,
        "conductorNombre":null,
        "conductorDocumento":null,
        "conductorCelular":null,
        "claseVehiculo":null,
        "fechaInicioCargue":null,
        "horaInicioCargue":null,
        "fechaFinCargue":null,
        "horaFinCargue":null,
        "precinto":null,
        "remesa":null,
        "manifiesto":null,
        "fechaRemesa":null,
        "fechaManifiesto":null,
        "ordenCargue":null,
        "ordenCargueFechaCreacion":null,
        "fechaRecepcionDocumentosCargue":null,
        "horaRecepcionDocumentosCargue":null,
        "fechaSalidaRuta":null,
        "horaSalidaRuta":null
      },
      Devolucion_Contenedor: {
        "solicitudServicio":null,
        "contenedorNumero":null,
        "fechaEntregaVacio":null,
        "horaEntregaVacio":null
      },
      Entrega_Mercancia: {
        "solicitudServicio":null,
        "itemEntrega":null,
        "recibidoNombre":null,
        "recibidoCelular":null,
        "recibidoFecha":null,
        "recibidoHora":null
      },
      Llegada_sitio: {
        "solicitudServicio":null,
        "itemEntrega":null,
        "LLegadaSitioFecha":null,
        "LLegadaSitioHora":null,
        "LlegadaSitioObservacion":null
      },
      Control: {
        "solicitudServicio":null,
        "puestoControlNombre":null,
        "puestoControlFecha":null,
        "puestoControlHora":null,
        "puestoControlLatitud":null,
        "puestoControlLongitud":null,
        "puestoControlObservacion":null
      },
      Novedad: {
        "solicitudServicio":null,
        "novedadNombre":null,
        "novedadFecha":null,
        "novedadHora":null,
        "novedadObservacion":null
      },
      novedadSyscom: {
        'codNovedad': null,
        'novedad': null,
        'fechaNovedad': null,
        'horaNovedad': null,
        'latitudNovedad': null,
        'longitudNovedad': null,
        'observacion': null,
        'solicitud': null
      },
      auxiliar: {
        "solicitud":null,
        "auxiliarNombre":null,
        "auxiliarDocumento":null,
        "auxiliarTelefono":null
      },
      auxiliarOtro: {
        "solicitudServicio":null,
        "auxiliares":[
          {
            "auxiliarNombre":null,
            "auxiliarDocumento":null,
            "auxiliarTelefono":null
          }
        ]
      },
      cambioVehiculo: {
        "solicitudServicio":null,
        "vehiculo":null,
        "vehiculoModelo":null,
        "conductorNombre":null,
        "conductorDocumento":null,
        "conductorCelular":null,
        "observacion":null
      },
      factura: {
        "solicitudServicio":null,
        "numerofactura":null,
        "fechafactura":null,
        "valorfactura":null,
        "monedafactura":null
      },
      cierreOTM:{
        "solicitudServicio":null,
        "itemEntrega":null,
        "fechaCierreOtm":null,
        "horaCierreOtm":null
      },
      fechaInicioOtm: null,
      fechaFinOtm: null,
      optionsAdicional: [],
      optionsContenedor: [],
      optionsNovedad: [
        'NOVEDAD FIN DE SEMANA',
        'SIN NOVEDAD',
        'OTROS'
      ],
      adicionalDialog: false,
      despachoDialog: false,
      Devolucion_ContenedorDialog: false,
      Entrega_MercanciaDialog: false,
      Llegada_sitioDialog: false,
      ControlDialog: false,
      NovedadDialog: false,
      NovedadDialogSelect: false,
      NovedadDialogCoord: false,
      NovedadDialogSyscom: false,
      NovedadDialogSyscomOtro: false,
      auxiliarDialog: false,
      cambioVehiculoDialog: false,
      facturaDialog: false,
      cierreOTMDialog: false,
      vehiculoDialog: false,
      token: null,
      numOrden: null
    }
  },
  AcceptedOrder: null,
  computed: {
    novedadCargue: function () {
      return this.service.info_novedad.filter(val => val.novedadNombre == 'CARGUE');
    },
    novedadTransito: function () {
      return this.service.info_novedad.filter(val => val.novedadNombre == 'TRANSITO');
    },
    novedadDescargue: function () {
      return this.service.info_novedad.filter(val => val.novedadNombre == 'DESCARGUE');
    },
    novedadGeneral: function () {
      return this.service.info_novedad.filter(val => (val.novedadNombre != 'EN SITIO' && val.novedadNombre != 'TRANSITO' && val.novedadNombre != 'CARGUE' && val.novedadNombre != 'DESCARGUE'));
    },
    novedadCoord: function () {
      return this.service.info_orden_servicio[this.idOrderService].novedad.filter(val => val.codNovedad == '01');
    },
    novedadPlataforma: function () {
      return this.service.info_orden_servicio[this.idOrderService].novedad.filter(val => val.codNovedad == '02');
    },
    novedadInicio: function () {
      return this.service.info_orden_servicio[this.idOrderService].novedad.filter(val => val.codNovedad == '03');
    },
    novedadFin: function () {
      return this.service.info_orden_servicio[this.idOrderService].novedad.filter(val => val.codNovedad == '04');
    },
    novedadSalida: function () {
      return this.service.info_orden_servicio[this.idOrderService].novedad.filter(val => val.codNovedad == '05');
    },
    novedadCoordDesc: function () {
      return this.service.info_orden_servicio[this.idOrderService].novedad.filter(val => val.codNovedad == '06');
    },
    novedadPlataformaDesc: function () {
      return this.service.info_orden_servicio[this.idOrderService].novedad.filter(val => val.codNovedad == '07');
    },
    novedadFinDesc: function () {
      return this.service.info_orden_servicio[this.idOrderService].novedad.filter(val => val.codNovedad == '08');
    },
    novedadDevMerc: function () {
      return this.service.info_orden_servicio[this.idOrderService].novedad.filter(val => val.codNovedad == '09');
    },
    novedadProgEnt: function () {
      return this.service.info_orden_servicio[this.idOrderService].novedad.filter(val => val.codNovedad == '10');
    },
    itemVehiculo: function () {
      return this.service.info_orden_servicio[this.idOrderService].vehiculo;
    }
  },
  created() {
    if(this.$store.state.auth.scopes) {
      const scopes = this.$store.state.auth.scopes.split(",");
      this.getAction(scopes);
    }
    this.AcceptedOrder = new AcceptedOrder();
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'assets/js/methods.js')
    document.head.appendChild(recaptchaScript)
  },
  async mounted() {
    this.AcceptedOrder.getOrder(this.$route.params.id).then(data => {
      this.service = data;
      this.idOrderService = this.findIndexById(this.$route.params.serv);
      this.numOrden = (this.service.info_orden_servicio[this.idOrderService].info_syscomResponse !== null) ? this.service.info_orden_servicio[this.idOrderService].info_syscomResponse.Pedido : null;
      this.optionsContenedor = this.service.info_entregas[0].contenedor;

      this.AcceptedOrder.getApiRestData(this.service.solicitudServicio, this.$route.params.serv).then(data => {
        this.$toast.add({
          severity:'success',
          summary: data.message,
          detail: 'Documentos actualizados.',
          life: 5000
        });
      })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error al traer los Documentos',
              detail: 'Error al traer los Documentos',
              life: 5000
            });
          });

      this.loading = false;
    })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error al traer los datos',
            detail: 'Error al traer los datos',
            life: 5000
          });
          this.loading = false;
        });

    this.AcceptedOrder.getAdicional().then(data => {
      this.optionsAdicional = data;
      for (const dataKey in data) {
        this.optionsAdicional[dataKey].code = data[dataKey].codigo;
        this.optionsAdicional[dataKey].name = data[dataKey].nombre;
      }
    })
        .catch(() => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error al traer los datos',
            detail: 'Error al traer los datos',
            life: 5000
          });
        });
  },
  methods: {
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.service.info_orden_servicio.length; i++) {
        if (this.service.info_orden_servicio[i].id == id) {
          index = i;
          break;
        }
      }

      return index;
    },
    openNewAdicional() {
      Object.assign(this.$data.adicional, this.$options.data().adicional);
      Object.assign(this.$data.adicionalOtro, this.$options.data().adicionalOtro);
      this.submitted = false;
      this.adicionalDialog = true;
    },
    async saveAdicional() {
      this.submitted = true;
      this.adicionalDialog = false;
      this.loading = true;

      if(this.service.active != 2) {
        this.token = await this.generateToken();
      }

      const config = {
        headers: {
          "Authorization": this.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };
      this.adicional.idEntrega = this.service.info_entregas[0].id;
      this.adicionalOtro.solicitudServicio = this.service.solicitudServicio;
      this.adicionalOtro.itemEntrega = this.adicional.itemServicioAdicional;
      this.adicionalOtro.valor = this.adicional.valor;
      this.adicionalOtro.valorTotal = this.adicional.valorTotal;
      this.adicionalOtro.cantidad = this.adicional.cantidad;
      this.adicionalOtro.observacion = this.adicional.observacionEmpresa;
      this.adicionalOtro.servicioAdicionalCodigo = this.adicional.servicioAdicional.code;
      this.adicional.servicioAdicional = this.adicional.servicioAdicional.name;


      var axios1, axios2;
      if(this.service.active == 2){
        axios1 = await new Promise((resolve)=>{
          resolve(null);
        });
      }else{
        var url;
        if (this.service.tipoSolicitud == 'DSV') {
          url = API_BASE_URL.replace('{replace}', 'api=Servicio.GeneradorCargaDsv.grabarSolicitudServicioAdicional');
        } else {
          url = API_BASE_BDP_URL.replace('{replace}', 'api=Servicio.GeneradorCargaBdp.grabarSolicitudServicioAdicional');
        }
        axios1 = await new Promise((resolve) => {
          axios
              .post(url, this.adicionalOtro, config)
              .then(response => {
                resolve(response);
              })
              .catch(err => {
                console.log(err.response.data.msg);
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error de solicitud',
                  detail: err.response.data.msg,
                  life: 5000
                });
                this.loading = false;
              });
        });
      }

      axios2 = await new Promise((resolve)=>{
        this.postApiLocal('adicional', this.adicional)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1, axios2]).then(() => {
        this.adicional.servicioAdicional = { "nombre": this.adicional.servicioAdicional };
        this.service.info_entregas[0].servicioAdicional.push(JSON.parse(JSON.stringify(this.adicional)));
        this.loading = false;
        if(this.service.active == 2){
          this.$toast.add({severity:'success', summary: 'Exito', detail: 'Almacenado con exito.', life: 5000});
        }else{
          this.$toast.add({severity:'success', summary: 'Exito', detail: axios1.data.data, life: 5000});
        }
      });
    },
    openNewDespacho() {
      Object.assign(this.$data.despacho, this.$options.data().despacho);
      this.despacho.solicitudServicio = this.service.solicitudServicio;
      this.submitted = false;
      this.despachoDialog = true;
    },
    async saveDespacho() {
      this.submitted = true;
      this.despachoDialog = false;
      this.loading = true;

      if(this.service.active != 2) {
        this.token = await this.generateToken();
      }

      const config = {
        headers: {
          "Authorization": this.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };

      var axios1, axios2;
      if(this.service.active == 2){
        axios1 = await new Promise((resolve)=>{
          resolve(null);
        });
      }else{
        var url;
        if (this.service.tipoSolicitud == 'DSV') {
          url = API_BASE_URL.replace('{replace}', 'api=Servicio.GeneradorCargaDsv.grabarCargueDespacho');
        } else {
          url = API_BASE_BDP_URL.replace('{replace}', 'api=Servicio.GeneradorCargaBdp.grabarCargueDespacho');
        }
        axios1 = await new Promise((resolve) => {
          axios
              .post(url, this.despacho, config)
              .then(response => {
                resolve(response);
              })
              .catch(err => {
                console.log(err.response.data.msg);
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error de solicitud',
                  detail: err.response.data.msg,
                  life: 5000
                });
                this.loading = false;
              });
        });
      }

      axios2 = await new Promise((resolve)=>{
        this.despacho.solicitud = this.despacho.solicitudServicio;
        this.postApiLocal('despacho', this.despacho)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1, axios2]).then(() => {
        this.service.info_despacho.push(JSON.parse(JSON.stringify(this.despacho)));
        this.loading = false;
        if(this.service.active == 2){
          this.$toast.add({severity:'success', summary: 'Exito', detail: 'Almacenado con exito.', life: 5000});
        }else{
          this.$toast.add({severity:'success', summary: 'Exito', detail: axios1.data.data, life: 5000});
        }
      });
    },
    openNewDevolucion_Contenedor() {
      Object.assign(this.$data.Devolucion_Contenedor, this.$options.data().Devolucion_Contenedor);
      this.Devolucion_Contenedor.solicitudServicio = this.service.solicitudServicio;
      this.submitted = false;
      this.Devolucion_ContenedorDialog = true;
    },
    async saveDevolucion_Contenedor() {
      this.submitted = true;
      this.Devolucion_ContenedorDialog = false;
      this.loading = true;

      if(this.service.active != 2) {
        this.token = await this.generateToken();
      }

      const config = {
        headers: {
          "Authorization": this.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };

      var axios1, axios2;
      this.Devolucion_Contenedor.contenedorNumero = this.Devolucion_Contenedor.contenedorNumero.contenedorNumero;

      if(this.service.active == 2){
        axios1 = await new Promise((resolve)=>{
          resolve(null);
        });
      }else {
        var url;
        if (this.service.tipoSolicitud == 'DSV') {
          url = API_BASE_URL.replace('{replace}', 'api=Servicio.GeneradorCargaDsv.grabarDevolucionContenedorVacio');
        } else {
          url = API_BASE_BDP_URL.replace('{replace}', 'api=Servicio.GeneradorCargaBdp.grabarDevolucionContenedorVacio');
        }
        axios1 = await new Promise((resolve) => {
          axios
              .post(url, this.Devolucion_Contenedor, config)
              .then(response => {
                resolve(response);
              })
              .catch(err => {
                console.log(err.response.data.msg);
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error de solicitud',
                  detail: err.response.data.msg,
                  life: 5000
                });
                this.loading = false;
              });
        });
      }

      axios2 = await new Promise((resolve)=>{
        this.Devolucion_Contenedor.solicitud = this.Devolucion_Contenedor.solicitudServicio;
        this.postApiLocal('devolucion', this.Devolucion_Contenedor)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1, axios2]).then(() => {
        this.service.info_devolucion_contenedor.push(JSON.parse(JSON.stringify(this.Devolucion_Contenedor)));
        this.loading = false;
        if(this.service.active == 2){
          this.$toast.add({severity:'success', summary: 'Exito', detail: 'Almacenado con exito.', life: 5000});
        }else{
          this.$toast.add({severity:'success', summary: 'Exito', detail: axios1.data.data, life: 5000});
        }
      });
    },
    openNewEntrega_Mercancia() {
      Object.assign(this.$data.Entrega_Mercancia, this.$options.data().Entrega_Mercancia);
      this.Entrega_Mercancia.solicitudServicio = this.service.solicitudServicio;
      this.submitted = false;
      this.Entrega_MercanciaDialog = true;
    },
    async saveEntrega_Mercancia() {
      this.submitted = true;
      this.Entrega_MercanciaDialog = false;
      this.loading = true;

      if(this.service.active != 2) {
        this.token = await this.generateToken();
      }

      const config = {
        headers: {
          "Authorization": this.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };

      var axios1, axios2;

      if(this.service.active == 2){
        axios1 = await new Promise((resolve)=>{
          resolve(null);
        });
      }else {
        var url;
        if (this.service.tipoSolicitud == 'DSV') {
          url = API_BASE_URL.replace('{replace}', 'api=Servicio.GeneradorCargaDsv.grabarEntregaMercancia');
        } else {
          url = API_BASE_BDP_URL.replace('{replace}', 'api=Servicio.GeneradorCargaBdp.grabarEntregaMercancia');
        }
        axios1 = await new Promise((resolve) => {
          axios
              .post(url, this.Entrega_Mercancia, config)
              .then(response => {
                resolve(response);
              })
              .catch(err => {
                console.log(err.response.data.msg);
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error de solicitud',
                  detail: err.response.data.msg,
                  life: 5000
                });
                this.loading = false;
              });
        });
      }

      axios2 = await new Promise((resolve)=>{
        this.postApiLocal('entregamerc', this.Entrega_Mercancia)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1, axios2]).then(() => {
        this.service.info_entrega_mercancia.push(JSON.parse(JSON.stringify(this.Entrega_Mercancia)));
        this.loading = false;
        if(this.service.active == 2){
          this.$toast.add({severity:'success', summary: 'Exito', detail: 'Almacenado con exito.', life: 5000});
        }else{
          this.$toast.add({severity:'success', summary: 'Exito', detail: axios1.data.data, life: 5000});
        }
      });
    },
    openNewLlegada_sitio() {
      Object.assign(this.$data.Llegada_sitio, this.$options.data().Llegada_sitio);
      Object.assign(this.$data.Novedad, this.$options.data().Novedad);
      this.Llegada_sitio.solicitudServicio = this.service.solicitudServicio;
      this.Novedad.solicitudServicio = this.service.solicitudServicio;
      this.Novedad.novedadNombre = 'EN SITIO';
      this.submitted = false;
      this.Llegada_sitioDialog = true;
    },
    async saveLlegada_sitio() {
      this.submitted = true;
      this.Llegada_sitioDialog = false;
      this.loading = true;

      if(this.service.active != 2) {
        this.token = await this.generateToken();
      }

      const config = {
        headers: {
          "Authorization": this.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };

      var axios1, axios2, axios3, axios4;

      if(this.service.active == 2){

        axios1 = await new Promise((resolve)=>{
          resolve(null);
        });

        //Novedad en sitio
        axios3 = await new Promise((resolve)=>{
          resolve(null);
        });

      } else {

        var url;
        if (this.service.tipoSolicitud == 'DSV') {
          url = API_BASE_URL.replace('{replace}', 'api=Servicio.GeneradorCargaDsv.grabarLLegadaSitio');
        } else {
          url = API_BASE_BDP_URL.replace('{replace}', 'api=Servicio.GeneradorCargaBdp.grabarLLegadaSitio');
        }
        axios1 = await new Promise((resolve) => {
          axios
              .post(url, this.Llegada_sitio, config)
              .then(response => {
                resolve(response);
              })
              .catch(err => {
                console.log(err.response.data.msg);
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error de solicitud',
                  detail: err.response.data.msg,
                  life: 5000
                });
                this.loading = false;
              });
        });

        //Novedad en sitio
        this.Novedad.novedadFecha = this.Llegada_sitio.LLegadaSitioFecha;
        this.Novedad.novedadHora = this.Llegada_sitio.LLegadaSitioHora;
        this.Novedad.novedadObservacion = this.Llegada_sitio.LlegadaSitioObservacion;
        if (this.service.tipoSolicitud == 'DSV') {
          url = API_BASE_URL.replace('{replace}', 'api=Servicio.GeneradorCargaDsv.grabarNovedad');
        } else {
          url = API_BASE_BDP_URL.replace('{replace}', 'api=Servicio.GeneradorCargaBdp.grabarNovedad');
        }
        axios3 = await new Promise((resolve) => {
          axios
              .post(url, this.Novedad, config)
              .then(response => {
                resolve(response);
              })
              .catch(err => {
                console.log(err.response.data.msg);
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error de solicitud',
                  detail: err.response.data.msg,
                  life: 5000
                });
                this.loading = false;
              });
        });

      }

      axios2 = await new Promise((resolve)=>{
        this.postApiLocal('sitio', this.Llegada_sitio)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      //Novedad en sitio
      axios4 = await new Promise((resolve)=>{
        this.Novedad.solicitud = this.Novedad.solicitudServicio;
        this.postApiLocal('novedad', this.Novedad)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1, axios2, axios3, axios4]).then(() => {
        this.service.info_llegada_sitio.push(JSON.parse(JSON.stringify(this.Llegada_sitio)));
        this.loading = false;
        if(this.service.active == 2){
          this.$toast.add({severity:'success', summary: 'Exito', detail: 'Almacenado con exito.', life: 5000});
        }else{
          this.$toast.add({severity:'success', summary: 'Exito', detail: axios1.data.data, life: 5000});
        }
      });
    },
    openNewControl() {
      Object.assign(this.$data.Control, this.$options.data().Control);
      this.Control.solicitudServicio = this.service.solicitudServicio;
      this.submitted = false;
      this.ControlDialog = true;
    },
    async saveControl() {
      this.submitted = true;
      this.ControlDialog = false;
      this.loading = true;

      if(this.service.active != 2) {
        this.token = await this.generateToken();
      }

      const config = {
        headers: {
          "Authorization": this.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };

      var axios1, axios2;

      if(this.service.active == 2){
        axios1 = await new Promise((resolve)=>{
          resolve(null);
        });
      }else {
        var url;
        if (this.service.tipoSolicitud == 'DSV') {
          url = API_BASE_URL.replace('{replace}', 'api=Servicio.GeneradorCargaDsv.grabarPuestoControl');
        } else {
          url = API_BASE_BDP_URL.replace('{replace}', 'api=Servicio.GeneradorCargaBdp.grabarPuestoControl');
        }
        axios1 = await new Promise((resolve) => {
          axios
              .post(url, this.Control, config)
              .then(response => {
                resolve(response);
              })
              .catch(err => {
                console.log(err.response.data.msg);
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error de solicitud',
                  detail: err.response.data.msg,
                  life: 5000
                });
                this.loading = false;
              });
        });
      }

      axios2 = await new Promise((resolve)=>{
        this.postApiLocal('control', this.Control)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1, axios2]).then(() => {
        this.service.info_control.push(JSON.parse(JSON.stringify(this.Control)));
        this.loading = false;
        if(this.service.active == 2){
          this.$toast.add({severity:'success', summary: 'Exito', detail: 'Almacenado con exito.', life: 5000});
        }else{
          this.$toast.add({severity:'success', summary: 'Exito', detail: axios1.data.data, life: 5000});
        }
      });
    },
    openNewNovedad(novedad) {
      Object.assign(this.$data.Novedad, this.$options.data().Novedad);
      this.Novedad.solicitudServicio = this.service.solicitudServicio;
      this.submitted = false;
      if(novedad != '*'){
        this.Novedad.novedadNombre = novedad;
        this.NovedadDialog = true;
      }else{
        this.NovedadDialogSelect = true;
      }
    },
    async saveNovedad() {
      this.submitted = true;
      this.NovedadDialog = false;
      this.NovedadDialogSelect = false;
      this.loading = true;

      if(this.service.active != 2) {
        this.token = await this.generateToken();
      }

      const config = {
        headers: {
          "Authorization": this.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };

      var axios1, axios2;
      if(this.service.active == 2){
        axios1 = await new Promise((resolve)=>{
          resolve(null);
        });
      }else {
        var url;
        if (this.service.tipoSolicitud == 'DSV') {
          url = API_BASE_URL.replace('{replace}', 'api=Servicio.GeneradorCargaDsv.grabarNovedad');
        } else {
          url = API_BASE_BDP_URL.replace('{replace}', 'api=Servicio.GeneradorCargaBdp.grabarNovedad');
        }
        axios1 = await new Promise((resolve) => {
          axios
              .post(url, this.Novedad, config)
              .then(response => {
                resolve(response);
              })
              .catch(err => {
                console.log(err.response.data.msg);
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error de solicitud',
                  detail: err.response.data.msg,
                  life: 5000
                });
                this.loading = false;
              });
        });
      }

      axios2 = await new Promise((resolve)=>{
        this.Novedad.solicitud = this.Novedad.solicitudServicio;
        this.postApiLocal('novedad', this.Novedad)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1, axios2]).then(() => {
        this.service.info_novedad.push(JSON.parse(JSON.stringify(this.Novedad)));
        this.loading = false;
        if(this.service.active == 2){
          this.$toast.add({severity:'success', summary: 'Exito', detail: 'Almacenado con exito.', life: 5000});
        }else{
          this.$toast.add({severity:'success', summary: 'Exito', detail: axios1.data.data, life: 5000});
        }
      });
    },
    openNewNovedadSyscom(cod, novedad) {
      Object.assign(this.$data.novedadSyscom, this.$options.data().novedadSyscom);
      this.submitted = false;
      this.novedadSyscom.codNovedad = cod;
      this.novedadSyscom.novedad = novedad;
      if (this.novedadSyscom.codNovedad == '01' || this.novedadSyscom.codNovedad == '06'){
        this.NovedadDialogCoord = true;
      } else if (this.novedadSyscom.codNovedad == '10') {
        this.NovedadDialogSyscomOtro = true;
      } else {
        this.NovedadDialogSyscom = true;
      }
    },
    async saveNovedadSyscom() {
      this.submitted = true;
      this.NovedadDialogCoord = false;
      this.NovedadDialogSyscom = false;
      this.NovedadDialogSyscomOtro = false;
      this.loading = true;

      this.novedadSyscom.solicitud = this.service.info_orden_servicio[this.idOrderService].id;

      let axios1;

      axios1 = await new Promise((resolve)=>{
        this.postApiLocal('novedadsyscom', this.novedadSyscom)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1]).then(() => {
        //this.services[this.findIndexById(this.service.id)].novedad.push(JSON.parse(JSON.stringify(this.novedad)));
        this.service.info_orden_servicio[this.idOrderService].novedad.push(JSON.parse(JSON.stringify(this.novedadSyscom)));
        this.loading = false;
        this.$toast.add({severity:'success', summary: 'Exito', detail: 'Datos guardados correctamente.', life: 5000});
      });
    },
    openNewAuxiliar() {
      Object.assign(this.$data.auxiliar, this.$options.data().auxiliar);
      Object.assign(this.$data.auxiliarOtro, this.$options.data().auxiliarOtro);
      this.auxiliar.solicitudServicio = this.service.solicitudServicio;
      this.auxiliarOtro.solicitudServicio = this.service.solicitudServicio;
      this.submitted = false;
      this.auxiliarDialog = true;
    },
    async saveAuxiliar() {
      this.submitted = true;
      this.auxiliarDialog = false;
      this.loading = true;

      if(this.service.active != 2) {
        this.token = await this.generateToken();
      }

      const config = {
        headers: {
          "Authorization": this.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };

      var axios1, axios2;
      if(this.service.active == 2){
        axios1 = await new Promise((resolve)=>{
          resolve(null);
        });
      }else {
        this.auxiliarOtro.auxiliares[0].auxiliarDocumento = this.auxiliar.auxiliarDocumento;
        this.auxiliarOtro.auxiliares[0].auxiliarNombre = this.auxiliar.auxiliarNombre;
        this.auxiliarOtro.auxiliares[0].auxiliarTelefono = this.auxiliar.auxiliarTelefono;
        var url;
        if (this.service.tipoSolicitud == 'DSV') {
          url = API_BASE_URL.replace('{replace}', 'api=Servicio.GeneradorCargaDsv.grabarAuxiliar');
        } else {
          url = API_BASE_BDP_URL.replace('{replace}', 'api=Servicio.GeneradorCargaBdp.grabarAuxiliar');
        }
        axios1 = await new Promise((resolve) => {
          axios
              .post(url, this.auxiliarOtro, config)
              .then(response => {
                resolve(response);
              })
              .catch(err => {
                console.log(err.response.data.msg);
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error de solicitud',
                  detail: err.response.data.msg,
                  life: 5000
                });
                this.loading = false;
              });
        });
      }

      axios2 = await new Promise((resolve)=>{
        this.auxiliar.solicitud = this.service.solicitudServicio;
        this.postApiLocal('auxiliar', this.auxiliar)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1, axios2]).then(() => {
        this.service.info_auxiliares.push(JSON.parse(JSON.stringify(this.auxiliar)));
        this.loading = false;
        if(this.service.active == 2){
          this.$toast.add({severity:'success', summary: 'Exito', detail: 'Almacenado con exito.', life: 5000});
        }else{
          this.$toast.add({severity:'success', summary: 'Exito', detail: axios1.data.data, life: 5000});
        }
      });
    },
    openNewCambioVehiculo() {
      Object.assign(this.$data.cambioVehiculo, this.$options.data().cambioVehiculo);
      this.cambioVehiculo.solicitudServicio = this.service.solicitudServicio;
      this.submitted = false;
      this.cambioVehiculoDialog = true;
    },
    async saveCambioVehiculo() {
      this.submitted = true;
      this.cambioVehiculoDialog = false;
      this.loading = true;

      if(this.service.active != 2) {
        this.token = await this.generateToken();
      }

      const config = {
        headers: {
          "Authorization": this.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };

      var axios1, axios2;
      if(this.service.active == 2){
        axios1 = await new Promise((resolve)=>{
          resolve(null);
        });
      }else {
        var url;
        if (this.service.tipoSolicitud == 'DSV') {
          url = API_BASE_URL.replace('{replace}', 'api=Servicio.GeneradorCargaDsv.cambioVehiculo');
        } else {
          url = API_BASE_BDP_URL.replace('{replace}', 'api=Servicio.GeneradorCargaBdp.cambioVehiculo');
        }
        axios1 = await new Promise((resolve) => {
          axios
              .post(url, this.cambioVehiculo, config)
              .then(response => {
                resolve(response);
              })
              .catch(err => {
                console.log(err.response.data.msg);
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error de solicitud',
                  detail: err.response.data.msg,
                  life: 5000
                });
                this.loading = false;
              });
        });
      }

      axios2 = await new Promise((resolve)=>{
        this.postApiLocal('cambioveh', this.cambioVehiculo)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1, axios2]).then(() => {
        this.service.info_cambio_vehiculo.push(JSON.parse(JSON.stringify(this.cambioVehiculo)));
        this.loading = false;
        if(this.service.active == 2){
          this.$toast.add({severity:'success', summary: 'Exito', detail: 'Almacenado con exito.', life: 5000});
        }else{
          this.$toast.add({severity:'success', summary: 'Exito', detail: axios1.data.data, life: 5000});
        }
      });
    },
    openNewFactura() {
      Object.assign(this.$data.factura, this.$options.data().factura);
      this.factura.solicitudServicio = this.service.solicitudServicio;
      this.submitted = false;
      this.facturaDialog = true;
    },
    async saveFactura() {
      this.submitted = true;
      this.facturaDialog = false;
      this.loading = true;

      if(this.service.active != 2) {
        this.token = await this.generateToken();
      }

      const config = {
        headers: {
          "Authorization": this.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };

      var axios1, axios2;
      if(this.service.active == 2){
        axios1 = await new Promise((resolve)=>{
          resolve(null);
        });
      }else {
        var url;
        if (this.service.tipoSolicitud == 'DSV') {
          url = API_BASE_URL.replace('{replace}', 'api=Servicio.GeneradorCargaDsv.grabarFactura');
        } else {
          url = API_BASE_BDP_URL.replace('{replace}', 'api=Servicio.GeneradorCargaBdp.grabarFactura');
        }
        axios1 = await new Promise((resolve) => {
          axios
              .post(url, this.factura, config)
              .then(response => {
                resolve(response);
              })
              .catch(err => {
                console.log(err.response.data.msg);
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error de solicitud',
                  detail: err.response.data.msg,
                  life: 5000
                });
                this.loading = false;
              });
        });
      }

      axios2 = await new Promise((resolve)=>{
        this.postApiLocal('factura', this.factura)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1, axios2]).then(() => {
        this.service.info_factura.push(JSON.parse(JSON.stringify(this.factura)));
        this.loading = false;
        if(this.service.active == 2){
          this.$toast.add({severity:'success', summary: 'Exito', detail: 'Almacenado con exito.', life: 5000});
        }else{
          this.$toast.add({severity:'success', summary: 'Exito', detail: axios1.data.data, life: 5000});
        }
      });
    },
    openNewCierreOTM() {
      Object.assign(this.$data.cierreOTM, this.$options.data().cierreOTM);
      this.cierreOTM.solicitudServicio = this.service.solicitudServicio;
      this.submitted = false;
      this.cierreOTMDialog = true;
    },
    async saveCierreOTM() {
      this.submitted = true;
      this.cierreOTMDialog = false;
      this.loading = true;

      if(this.service.active != 2) {
        this.token = await this.generateToken();
      }

      const config = {
        headers: {
          "Authorization": this.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };

      var axios1, axios2;
      if(this.service.active == 2){
        axios1 = await new Promise((resolve)=>{
          resolve(null);
        });
      }else {
        var url;
        if (this.service.tipoSolicitud == 'DSV') {
          url = API_BASE_URL.replace('{replace}', 'api=Servicio.GeneradorCargaDsv.grabarCierreOtm');
        } else {
          url = API_BASE_BDP_URL.replace('{replace}', 'api=Servicio.GeneradorCargaBdp.grabarCierreOtm');
        }
        axios1 = await new Promise((resolve) => {
          axios
              .post(url, this.cierreOTM, config)
              .then(response => {
                resolve(response);
              })
              .catch(err => {
                console.log(err.response.data.msg);
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error de solicitud',
                  detail: err.response.data.msg,
                  life: 5000
                });
                this.loading = false;
              });
        });
      }

      axios2 = await new Promise((resolve)=>{
        this.cierreOTM['fechaInicio'] = this.fechaInicioOtm;
        this.cierreOTM['fechaFin'] = this.fechaFinOtm;
        this.postApiLocal('cierreotm', this.cierreOTM)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1, axios2]).then(() => {
        this.service.info_cierre_otm.push(JSON.parse(JSON.stringify(this.cierreOTM)));
        this.loading = false;
        if(this.service.active == 2){
          this.$toast.add({severity:'success', summary: 'Exito', detail: 'Almacenado con exito.', life: 5000});
        }else{
          this.$toast.add({severity:'success', summary: 'Exito', detail: axios1.data.data, life: 5000});
        }
      });
    },
    openNewVehiculo() {
      Object.assign(this.$data.vehiculoItem, this.$options.data().vehiculoItem);
      this.vehiculoItem.solicitud = this.service.info_orden_servicio[this.idOrderService].id;
      this.submitted = false;
      this.vehiculoDialog = true;
    },
    async saveVehiculo() {
      this.submitted = true;
      this.vehiculoDialog = false;
      this.loading = true;

      var axios1;

      axios1 = await new Promise((resolve)=>{
        this.postApiLocal('vehiculo', this.vehiculoItem)
            .then(() => {
              resolve(resolve);
            })
            .catch(() => {
              this.$toast.add({severity:'error', summary: 'Error de solicitud', detail:'No se ha podido procesar la solicitud', life: 5000});
              this.loading = false;
            });
      });

      Promise.all([axios1]).then(() => {
        this.service.info_orden_servicio[this.idOrderService].vehiculo.push(JSON.parse(JSON.stringify(this.vehiculoItem)));
        this.loading = false;
        this.$toast.add({severity:'success', summary: 'Exito', detail: 'Almacenado con exito.', life: 5000});
      });
    },
    postApiLocal(slug, data){
      const config = {
        headers: {
          "Authorization": "Bearer " + this.$store.state.auth.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };
      return axios
          .post(API_LOCAL_URL+slug, data, config);
    },
    getAction(scopes){
      for (var key in scopes) {
        this.permisos[scopes[key]] = true;
      }
    },
    generateToken(){
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      };
      var formData, url;
      if (this.service.tipoSolicitud == 'DSV') {
        formData = {
          usuario_login: 'WEB.SERVICESARVI',
          usuario_password: "Sarviweb2021"
        };
        url = API_BASE_URL.replace('{replace}', 'api=Servicio.Seguridad.login', config);
      } else {
        formData = {
          usuario_login: 'WEBSERVICE SARVI',
          usuario_password: "Cambiame2026"
        };
        url = API_BASE_BDP_URL.replace('{replace}', 'api=Servicio.Seguridad.login', config);
      }

      return new Promise((resolve) => {
        axios
            .post(url, formData)
            .then(response => {
              if (response.data.success) {
                resolve(response.data.data.token);
              } else {
                this.$toast.add({
                  severity: 'error',
                  summary: 'No se ha podido iniciar sesión',
                  detail: 'No se ha podido iniciar sesión',
                  life: 5000
                });
              }
            })
            .catch(err => {
              console.log(err.response.data.msg);
              this.$toast.add({
                severity: 'error',
                summary: 'No se ha podido iniciar sesión',
                detail: err.response.data.msg,
                life: 5000
              });
            });
      });
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

.p-datatable .p-column-filter {
  display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: .5rem;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
  padding: 1rem;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        > td {
          text-align: left;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: .4rem;
            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
          }
        }
      }
    }
  }
}
</style>
